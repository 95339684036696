import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import eachserviceStyles from "../styles/eachservice.module.scss"
import Layout from "../components/layout"


const MaterailCleaningPage = ((props) => {
  return (
    <Layout>
      <div className={eachserviceStyles.maindiv}>
        <div className={eachserviceStyles.headerdiv}>Carpet, Mattress and Couch Cleaning Services</div>
        <div className={eachserviceStyles.imagediv}>
          <Img className={eachserviceStyles.imagetag} fluid={props.data.carpetMattressCouchCleaning.childImageSharp.fluid} alt="domesticCleaning"/>
        </div>
        <div className={eachserviceStyles.textdiv}>
            Residents can trust and rely on for all their carpet and upholstery cleaning requirements. 
            Our carpet cleaning methods remove all dirt embedded deep down in the carpets and we leave your carpets fresh and dry in no time. 
            We use an effective steam cleaning method which effectively sucks out all deep seated dust from upholstery environmentally friendly cleaning leaving your sofas, couches and mattresses clean and fresh. 
            This is the best upholstery cleaning which is also environmentally friendly and will not affect individuals who suffer from allergies as we use chemicals with excellent green ratings. 
            We can collect your laundry- wash, dry and iron and bring it back when we are done - 
            or you can request us to send the lady to your house to do the ironing.
        </div>
      </div>
    </Layout>
  )
}
)

export default MaterailCleaningPage

export const pageQuery = graphql`
  query {
    carpetMattressCouchCleaning: file(relativePath: {eq: "CarpetMattressCouchCleaning.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`